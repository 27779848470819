import React from "react";
import { TableColumn } from "@backstage/core-components";
import { CatalogTable, CatalogTableRow } from "@backstage/plugin-catalog";
import { IconButton } from "@material-ui/core";
import GitHubIcon from "@material-ui/icons/GitHub";
import { EntityListContextProps } from "@backstage/plugin-catalog-react";

const createGitHubColumn = () : TableColumn<CatalogTableRow> => {
    return {
        title: 'GitHub',
        field: 'entity.metadata.annotations',
        type: 'string',
        hidden: false,
        searchable: true,
        resizable: true,
        sorting: false,
        align: 'center',
        cellStyle: {
          'padding': '0px 5px',
        },
        width: 'auto',
        render: ({entity}) => {
            const sourceLocationKey = "backstage.io/source-location"
            const sourceLocationValue = entity.metadata.annotations?.[sourceLocationKey];

            if (sourceLocationValue) {
                const githubRepoUrl = sourceLocationValue.replace(/^(url:)/, "");
                return (
                  <IconButton
                    size='small'
                    color='inherit'
                    target='_blank'
                    href={githubRepoUrl}
                    aria-label='github-link'
                  >
                      <GitHubIcon fontSize='small' />
                  </IconButton>);
            }

            return null;
        }
    }
}

export const customCatalogTableColumns = (entityListContext: EntityListContextProps) : TableColumn<CatalogTableRow>[] => {
    const columns = CatalogTable.defaultColumnsFunc(entityListContext);
    const kindFilter = entityListContext.filters.kind?.value;
    if (kindFilter === 'system') {
        columns.push(createGitHubColumn());
    }
    return columns;
}
